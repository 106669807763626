import React from "react";
import { ThemeProvider } from "styled-components";
import { LocationProvider } from "@reach/router";
import { colors, lineHeight, breakpoints } from "../styles/Themes";

import GlobalStyle from "../styles/GlobalStyle";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Wrapper } from "../components/Design";

// Wraps the ThemeContext.Provider around the Layout
export default function Layout({ children }) {
  const theme = {
    colors,
    lineHeight,
    breakpoints,
  };

  return (
    <LocationProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle theme={theme} />
        <Wrapper>
          <Header />
          {children}
          <Footer />
        </Wrapper>
      </ThemeProvider>
    </LocationProvider>
  );
}
