import React from "react"
import Layout from "./src/templates/Layout"

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname)
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)
// export const wrapRootElement = ({ element, props }) => (
//   <Layout {...props}>{element}</Layout>
// )
