import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: auto;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 20px;
`;

// MODALS
export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  background: ${(props) => props.theme.colors.overlay};
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  max-width: 360px;
  width: 100%;
  margin: 20px;
  /* @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
    margin: 20px auto;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0; */
  padding: 20px 20px 10px 20px;
  z-index: 999999;
  background: ${(props) => props.theme.colors.background};
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
`;

export const ModalHeadline = styled.div`
  margin-bottom: 10px;
  line-height: ${(props) => props.theme.lineHeight};
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
`;

export const ModalText = styled.div`
  margin-bottom: 20px;
  line-height: ${(props) => props.theme.lineHeight};
  text-align: center;
`;

// Input
export const Input = styled.input`
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 100%;
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid #ddd;
`;

// Headline
export const H1 = styled.h1`
  text-align: center;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 60px;
`;

// Error
export const FalscheAntwort = styled.div`
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  color: #d20000;
`;

// Buttons
export const ButtonContainer = styled.div`
  display: flex;
`;

export const Button = styled.div`
  width: 100%;
  height: 50px;
  cursor: pointer;
  background: ${(props) => (props.secondary ? "#fff" : "#000")};
  color: ${(props) => (props.secondary ? "#000" : "#fff")};
  /* border: 1px solid #000; */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => (props.important ? "20px" : "10px")};
`;

// Code
// export const Code = styled.div`
//   text-align: center;
//   font-weight: bold;
//   font-size: 20px;
//   margin-bottom: 20px;
//   padding: 20px;
// `;

export const CodeInfo = styled.div`
  text-align: center;
  line-height: ${(props) => props.theme.lineHeight};
  margin-bottom: 20px;
`;
