export const colors = {
  accent: "rgb(0,0,0)",
  background: "rgb(255,255,255)",
  primary: "rgb(0,0,0)",
  overlay: "rgba(200,200,200,0.8)",
};

export const lineHeight = "1.4"; // 1.625 = 26px | 1.75 geht auch (1.75*16px/10 = 28px Zeilenhöhe)

export const breakpoints = {
  xs: "400px",
  small: "600px",
  medium: "800px",
  large: "1000px",
  xl: "1400px",
};
