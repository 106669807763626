import React from "react";
import styled from "styled-components";

import { Container } from "./Design";

const FooterLinks = styled.div`
  text-align: center;
  margin-top: 60px;
  padding-bottom: 20px;
  font-size: 12px;
  a {
    margin: 0 5px;
  }
`;

const Footer = () => {
  return (
    <Container>
      <FooterLinks>
        <a href="https://wirinuer.de/impressum/">Impressum</a>
        <a href="https://wirinuer.de/datenschutzerklaerung/">Datenschutz</a>
      </FooterLinks>
    </Container>
  );
};

export default Footer;
