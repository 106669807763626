import { createGlobalStyle } from "styled-components";
import "@fontsource/open-sans";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/700.css";

import backgroundImage from "../images/background.jpg";

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  } 

  html {
    font-size: 16px;
    margin: 0;
    padding: 0;
    border: 0;
    height: 100%;
  }

  body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center, dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    position: relative;
    line-height: 1;
    background: rgb(251, 245, 233);
    background-image: url(${backgroundImage});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: ${(props) => props.theme.colors.primary};
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }

  a {
    text-decoration: none;
    color: inherit;
    outline: 0;
  }
`;

export default GlobalStyle;
